export function remToPx(value) {
  return Math.round(parseFloat(value) * 16)
}

export function pxToRem(value) {
  return `${value / 16}rem`
}

export const reversePath = () => {
  const {search, pathname} = window.location
  if (pathname === '/affiliate') {
    return pathname + search
  }
  const originPath = pathname.substring(0, pathname.lastIndexOf('/')) || '/'
  return originPath + search
}

export const reversePathWithHash = ({path = '/', hasPath = ''}) => {
  const {search} = window.location
  return path + hasPath + search
}

export const currentPath = (hasPath = '') => {
  const {search, pathname} = window.location
  return pathname + hasPath + search
}

export const isAffiliateOrLandingHome =
  window.location.pathname === '/' || window.location.pathname.includes('/affiliate')

export const isAffiliateLegalNotice = window.location.pathname === '/affiliate/legal-notice'
