import {pxToRem} from 'utils'

export function responsiveFontSizes({sm, md, lg}) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  }
}

const FONT_PRIMARY = 'Roboto, sans-serif'

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 600,
    lineHeight: pxToRem(49),
    fontSize: pxToRem(41),
    ...responsiveFontSizes({sm: 41, md: 41, lg: 41}),
  },
  h2: {
    fontWeight: 700,
    fontSize: pxToRem(30),
    ...responsiveFontSizes({sm: 30, md: 30, lg: 30}),
  },
  h3: {
    fontWeight: 700,
    lineHeight: pxToRem(30),
    fontSize: pxToRem(24),
    ...responsiveFontSizes({sm: 24, md: 24, lg: 24}),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: pxToRem(28),
    fontSize: pxToRem(20),
    ...responsiveFontSizes({sm: 20, md: 20, lg: 20}),
  },
  body2: {
    lineHeight: pxToRem(22),
    fontSize: pxToRem(14),
  },
  footer: {
    lineHeight: pxToRem(28),
    fontSize: pxToRem(14),
    fontWeight: 300,
  },
  button: {
    fontWeight: 600,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(18),
    textTransform: 'capitalize',
    ...responsiveFontSizes({sm: 14, md: 14, lg: 14}),
  },
}

export default typography
