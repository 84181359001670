import {styled, Grid, Box} from '@mui/material'

export const BWGContainer = styled('div')(({theme}) => ({
  padding: '0 0 0 30px',
  height: 'auto',
  width: '100%',
  borderRadius: '10px',
  boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.25)',
  background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, 
               ${theme.palette.primary.dark} 100%);`,
  [theme.breakpoints.down('sm')]: {
    padding: '0 0 0 20px',
  },
}))

export const ItemLeft = props => (
  <Grid item md={12} sm={13} xs={20} order={{xs: 2, sm: 1}} {...props} />
)

export const ItemRight = props => (
  <Grid item textAlign='center' md={8} sm={7} xs={20} order={{xs: 1, sm: 2}} {...props} />
)

export const BigLeftContainer = styled('div')(({theme}) => ({
  display: 'flex',
  padding: '30px 0 30px 0',
  [theme.breakpoints.down('sm')]: {
    padding: '0 20px 30px 0',
  },
}))

export const ItemLeftContainer = styled('div')(({theme}) => ({
  display: 'flex',
  rowGap: '25px',
  flexDirection: 'column',
  justifyContent: 'space-around',
  borderBottom: `4px solid ${theme.palette.secondary.main}`,
  padding: '0 0 30px 0',
}))

export const ImageWrapper = styled('div')`
  height: auto;
  width: auto;
`

export const ImageBox = props => (
  <Box
    component='img'
    width='auto'
    height={{
      xs: '110px',
      sm: '220px',
      md: '370px',
      lg: '470px',
      xl: '470px',
    }}
    {...props}
  />
)
