import {styled} from '@mui/material/styles'
import {Stack, Grid, Box, Typography} from '@mui/material'
import TrackCustomPreference from 'consentCheck'

export const TokenImage = styled(props => (
  <Box
    component='img'
    display={{
      xs: 'none',
      sm: 'block',
    }}
    {...props}
  />
))({
  width: 150,
  height: 'auto',
})

export const ContentWrapper = styled(props => (
  <Stack
    justifyContent='flex-end'
    rowGap={2}
    flexGrow={1}
    alignItems={{
      xs: 'center',
      sm: 'flex-start',
    }}
    textAlign={{
      xs: 'center',
      sm: 'left',
    }}
    {...props}
  />
))({})

export const MetamaskGrid = styled(props => (
  <Grid
    display='flex'
    justifyContent={{
      xs: 'center',
      sm: 'flex-end',
    }}
    {...props}
  />
))({})

export const IconWrapper = styled('a')({
  display: 'grid',
  height: 80,
  width: '100%',
})

export const Icon = styled(props => (
  <Box
    component='img'
    alignSelf='center'
    justifySelf='center'
    maxWidth='100%'
    width={60}
    {...props}
  />
))({})

export const WalletIcon = ({src, link, text, ...rest}) => (
  <>
    <IconWrapper
      href={link}
      target='_blank'
      onClick={() =>
        TrackCustomPreference({
          action: 'Link Clicked',
          properties: {url: link, text: text, meaning: 'Wallet link'},
        })
      }
    >
      <Icon src={src} {...rest} />
    </IconWrapper>
    <Typography variant='body2' textAlign='center'>
      {text}
    </Typography>
  </>
)
