import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Grid, Typography, List} from '@mui/material'

import {useMediaQuery} from 'hooks'

import FromRows from './FromRows'
import {Container, BottomContainer, BulletedListItem} from './Advantages.styles'

const Advantages = () => {
  const {t} = useTranslation()
  const isMobile = useMediaQuery('sm', 'down')

  const platforms = t('advantages.platforms', {returnObjects: true}) || []
  const representative = t('advantages.representative', {returnObjects: true}) || []
  const tradability = t('advantages.tradability', {returnObjects: true}) || []
  const goldBacked = t('advantages.goldBacked', {returnObjects: true}) || []
  const storageFees = t('advantages.storageFees', {returnObjects: true}) || []
  const ownership = t('advantages.ownership', {returnObjects: true}) || []

  const Dataset = {
    '@context': ['https://schema.org', {csvw: 'https://www.w3.org/ns/csvw#'}],
    '@type': 'Dataset',
    description: t('advantages.description'),
    name: t('advantages.title'),
    mainEntity: {
      '@type': 'csvw:Table',
      'csvw:tableSchema': {
        'csvw:columns': [
          {
            'csvw:name': platforms[1],
            'csvw:datatype': 'string',
            'csvw:cells': [
              {
                'csvw:value': representative[1],
                'csvw:primaryKey': representative[0],
              },
              {
                'csvw:value': tradability[1],
                'csvw:primaryKey': tradability[0],
              },
              {
                'csvw:value': goldBacked[1],
                'csvw:primaryKey': goldBacked[0],
              },
              {
                'csvw:value': storageFees[1],
                'csvw:primaryKey': storageFees[0],
              },
              {
                'csvw:value': ownership[1],
                'csvw:primaryKey': ownership[0],
              },
            ],
          },
          {
            'csvw:name': platforms[2],
            'csvw:datatype': 'string',
            'csvw:cells': [
              {
                'csvw:value': representative[2],
                'csvw:primaryKey': representative[0],
              },
              {
                'csvw:value': tradability[2],
                'csvw:primaryKey': tradability[0],
              },
              {
                'csvw:value': goldBacked[2],
                'csvw:primaryKey': goldBacked[0],
              },
              {
                'csvw:value': storageFees[2],
                'csvw:primaryKey': storageFees[0],
              },
              {
                'csvw:value': ownership[2],
                'csvw:primaryKey': ownership[0],
              },
            ],
          },
          {
            'csvw:name': platforms[3],
            'csvw:datatype': 'string',
            'csvw:cells': [
              {
                'csvw:value': representative[3],
                'csvw:primaryKey': representative[0],
              },
              {
                'csvw:value': tradability[3],
                'csvw:primaryKey': tradability[0],
              },
              {
                'csvw:value': goldBacked[3],
                'csvw:primaryKey': goldBacked[0],
              },
              {
                'csvw:value': storageFees[3],
                'csvw:primaryKey': storageFees[0],
              },
              {
                'csvw:value': ownership[3],
                'csvw:primaryKey': ownership[0],
              },
            ],
          },
          {
            'csvw:name': platforms[4],
            'csvw:datatype': 'string',
            'csvw:cells': [
              {
                'csvw:value': representative[4],
                'csvw:primaryKey': representative[0],
              },
              {
                'csvw:value': tradability[4],
                'csvw:primaryKey': tradability[0],
              },
              {
                'csvw:value': goldBacked[4],
                'csvw:primaryKey': goldBacked[0],
              },
              {
                'csvw:value': storageFees[4],
                'csvw:primaryKey': storageFees[0],
              },
              {
                'csvw:value': ownership[4],
                'csvw:primaryKey': ownership[0],
              },
            ],
          },
        ],
      },
    },
  }

  return (
    <React.Fragment>
      <script type='application/ld+json'>{JSON.stringify(Dataset)}</script>
      <Container>
        <Typography variant='h2' color='text.reverse' sx={{padding: {xs: '10px', sm: '0'}}}>
          {t('advantages.title')}
        </Typography>
        <Grid container mt={4}>
          <Grid container item xs={12}>
            <FromRows
              items={isMobile ? platforms.slice(0, -1) : platforms}
              borderBottom={false}
              bolder={['BWG']}
            />
          </Grid>
          <Grid container item xs={12}>
            <FromRows items={isMobile ? representative.slice(0, -1) : representative} />
          </Grid>
          <Grid container item xs={12}>
            <FromRows items={isMobile ? tradability.slice(0, -1) : tradability} />
          </Grid>
          <Grid container item xs={12}>
            <FromRows items={isMobile ? goldBacked.slice(0, -1) : goldBacked} />
          </Grid>
          <Grid container item xs={12}>
            <FromRows items={isMobile ? storageFees.slice(0, -1) : storageFees} />
          </Grid>
          <Grid container item xs={12}>
            <FromRows items={isMobile ? ownership.slice(0, -1) : ownership} borderBottom={false} />
          </Grid>
        </Grid>
        <BottomContainer>
          <Typography
            variant='body2'
            color='text.reverse'
            fontSize={{md: 20}}
            sx={{fontWeight: {xs: 'fontWeightMedium', sm: 'fontWeightRegular'}}}
          >
            <List>
              <BulletedListItem>• {t('advantages.list.list1')}</BulletedListItem>
              <BulletedListItem>• {t('advantages.list.list2')}</BulletedListItem>
              <BulletedListItem>• {t('advantages.list.list3')}</BulletedListItem>
            </List>
          </Typography>
          <Typography variant='body2' color='text.reverse' fontSize={{xs: 9}} lineHeight='14px'>
            <Trans i18nKey='advantages.note'>
              <i>digital</i>
            </Trans>
          </Typography>
        </BottomContainer>
      </Container>
    </React.Fragment>
  )
}

export default Advantages
