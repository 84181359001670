import {Container} from '@mui/material'

import HeroSection from './HeroSection'
import BWGSection from './BWGSection/BWGSection'
import BusinessModel from './BusinessModel/BusinessModel'
import Sustainability from './Sustainability/Sustainability'
import SliderSection from './SliderSection'
import ConnectWallet from './ConnectWallet'
import Advantages from './Advantages'
import AppsSection from './AppsSection'
import Tutorial from './Tutorial'

const HomeView = () => {
  return (
    <Container>
      <HeroSection />
      <BWGSection />
      <Tutorial />
      <BusinessModel />
      <Sustainability />
      <ConnectWallet />
      <SliderSection />
      <AppsSection />
      <Advantages />
    </Container>
  )
}

export default HomeView
