import {IconButton} from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import {Wrapper, Number, Dot} from './SlideController.styles'

const compare = (value, current) => (value === current ? 1 : 0)

const SlideController = ({swiper, current}) => (
  <Wrapper>
    <IconButton onClick={() => swiper.slideTo((current + 2) % 3)} color='secondary'>
      <ArrowUpwardIcon
        sx={{
          stroke: ({palette: {text}}) => text.reverse,
          strokeWidth: 2,
          rotate: {xs: '270deg', sm: 'unset'},
        }}
      />
    </IconButton>
    <Number active={compare(0, current)} onClick={() => swiper.slideTo(0)}>
      1
    </Number>
    <Dot />
    <Number active={compare(1, current)} onClick={() => swiper.slideTo(1)}>
      2
    </Number>
    <Dot />
    <Number active={compare(2, current)} onClick={() => swiper.slideTo(2)}>
      3
    </Number>
    <IconButton onClick={() => swiper.slideTo((current + 1) % 3)} color='secondary'>
      <ArrowDownwardIcon
        sx={{
          stroke: ({palette: {text}}) => text.reverse,
          strokeWidth: 2,
          rotate: {xs: '270deg', sm: 'unset'},
        }}
      />
    </IconButton>
  </Wrapper>
)

export default SlideController
