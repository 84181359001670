import {GlobalStyles} from '@mui/material'

const DefaultStyles = () => (
  <GlobalStyles
    styles={{
      '*': {
        boxSizing: 'border-box',
      },
      html: {
        margin: 0,
        padding: 0,
        height: '100%',
        width: '100%',
        scrollBehavior: 'smooth',
      },
      body: {
        margin: 0,
        padding: 0,
        height: '100%',
        width: '100%',
      },
      '#root': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      },
    }}
  />
)

export default DefaultStyles
