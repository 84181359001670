import {useTranslation} from 'react-i18next'
import {Typography, Grid} from '@mui/material'

import Title from '../Title'
import VideoSection from './VideoSection'

import {SliderContainer, ItemRight, ItemRightContainer} from './sliders.styles'

const SignupSlide = () => {
  const {t} = useTranslation()

  return (
    <SliderContainer>
      <Grid
        container
        columnSpacing={{
          xs: 0,
          sm: 3,
        }}
        rowGap={2}
      >
        <Title />
        <VideoSection src='assets/sumsub.mp4' poster='/assets/sumsubThumbnail.png' />
        <ItemRight>
          <ItemRightContainer>
            <Typography variant='h1' color='text.reverse'>
              1
            </Typography>
            <Typography variant='h2' color='text.reverse'>
              {t('slider.slide1.title')}
            </Typography>
            <Typography variant='body1' color='text.reverse'>
              {t('slider.slide1.text')}
            </Typography>
          </ItemRightContainer>
        </ItemRight>
      </Grid>
    </SliderContainer>
  )
}
export default SignupSlide
