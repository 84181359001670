import {ItemFull, Title as TitleStyled} from '../Sliders/sliders.styles'

const Title = () => (
  <>
    <ItemFull>
      <TitleStyled>Get started in minutes</TitleStyled>
    </ItemFull>
  </>
)

export default Title
