import {styled} from '@mui/material/styles'
import {Box} from '@mui/system'

export const ConsentManagerContainer = styled(Box)({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 999,
})
