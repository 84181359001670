import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import {styled} from '@mui/material/styles'

export const BusinessModelContainer = styled('div')(({theme}) => ({
  width: '100%',
}))

export const FirstContainer = styled('div')(({theme}) => ({
  padding: '150px 0 70px 0',
  [theme.breakpoints.down('md')]: {
    padding: '70px 0 30px 0',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '70px 0 0 0',
  },
  height: '100%',
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
}))

export const Item = props => (
  <Grid
    item
    sm={4}
    xs={12}
    display='flex'
    pt={{
      xs: '40px',
      sm: '0px',
    }}
    {...props}
  />
)

export const ItemFirst = styled('div')(({theme}) => ({
  padding: '0 20px 0 0',
  display: 'flex',
  rowGap: '25px',
  flexDirection: 'column',
  height: '100%',
  borderRight: `4px solid ${theme.palette.secondary.main}`,
  [theme.breakpoints.down('sm')]: {
    borderRight: '0',
    borderLeft: `4px solid ${theme.palette.secondary.main}`,
    padding: '0 5px 0 10px',
  },
}))

export const ItemSecond = styled('div')(({theme}) => ({
  padding: '0 20px 0 20px',
  display: 'flex',
  rowGap: '25px',
  flexDirection: 'column',
  height: '100%',
  borderRight: `4px solid ${theme.palette.secondary.main}`,
  [theme.breakpoints.down('sm')]: {
    borderRight: '0',
    borderLeft: `4px solid ${theme.palette.secondary.main}`,
    padding: '0 5px 0 10px',
  },
}))

export const ItemThird = styled('div')(({theme}) => ({
  padding: '0 0 0 20px',
  display: 'flex',
  rowGap: '25px',
  flexDirection: 'column',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    borderRight: '0',
    borderLeft: `4px solid ${theme.palette.secondary.main}`,
    padding: '0 5px 0 10px',
  },
}))

export const ImageWrapper = props => (
  <Box
    display={{xs: 'none', sm: 'block'}}
    width='auto'
    height='auto'
    alignSelf={{sm: 'center'}}
    {...props}
  />
)

export const ImageWrapperPhone = props => (
  <Box sx={{minWidth: '85px'}} display={{xs: 'block', sm: 'none'}} {...props} />
)

export const ImageBoxPhone = props => (
  <Box display={{xs: 'block', sm: 'none'}} component='img' width='auto' height='55px' {...props} />
)

export const ImageBox = props => (
  <Box
    display={{xs: 'none', sm: 'block'}}
    component='img'
    width='auto'
    height={{
      xs: '55px',
      sm: '95px',
    }}
    {...props}
  />
)
