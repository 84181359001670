import {Trans, useTranslation} from 'react-i18next'
import {Typography, Grid, Link} from '@mui/material'

import Title from '../Title'
import VideoSection from './VideoSection'
import {loginUSUrl} from 'config'

import {SliderContainer, ItemRight, ItemRightContainer} from './sliders.styles'

//TODO edit text in SliderSection/howToData when edit CryptoSlide
const CryptoSlide = () => {
  const {t} = useTranslation()

  return (
    <SliderContainer>
      <Grid
        container
        columnSpacing={{
          xs: 0,
          sm: 3,
        }}
        rowGap={2}
      >
        <Title />
        <VideoSection src='assets/Slide2MetaMaskConnect.mp4' poster='/assets/Slide2Thumbnail.png' />
        <ItemRight>
          <ItemRightContainer>
            <Typography variant='h1' color='text.reverse'>
              2
            </Typography>
            <Typography variant='h2' color='text.reverse'>
              {t('slider.slide2.title')}
            </Typography>
            <Typography variant='body1' color='text.reverse'>
              <Trans
                i18nKey='slider.slide2.textComponent'
                components={{
                  1: (
                    <Link
                      color='text.reverse'
                      target='_blank'
                      underline='always'
                      href={loginUSUrl}
                    />
                  ),
                }}
                values={{link: loginUSUrl}}
              />
            </Typography>
          </ItemRightContainer>
        </ItemRight>
      </Grid>
    </SliderContainer>
  )
}

export default CryptoSlide
