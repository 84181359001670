import {styled} from '@mui/material/styles'

import Navbar from 'components/Navbar'
import Footer from 'components/Footer'

const Content = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
})

const LayoutMain = ({children}) => (
  <>
    <Navbar />
    <Content>{children}</Content>
    <Footer />
  </>
)

export default LayoutMain
