import Box from '@mui/material/Box'
import {styled} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

export const Wrapper = styled('div')(({theme}) => ({
  paddingTop: 100,
  paddingLeft: 15,
  [theme.breakpoints.down('sm')]: {
    paddingTop: 50,
  },
}))

export const Section = styled('div')({
  marginTop: 30,
})

// TODO delete if not used
export const SectionWithTopLine = styled('div')(({theme}) => ({
  marginTop: 30,
  borderTop: `2px solid ${theme.palette.secondary.main}`,
  padding: '30px 0 0 0',
}))

export const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
})

export const Img = styled('img')({
  height: 80,
  width: 80,
})

export const VideoMobile = styled(props => (
  <Box component='video' width='100%' height='auto' controls loop muted {...props} />
))({
  transform: 'scale(1.03)',
})

export const Link = styled(Typography)(({theme}) => ({
  fontWeight: 'bold',
  fontSize: 18,
  marginTop: 10,
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
}))

export const RegisterButton = styled(Button)(({theme}) => ({
  width: {xs: '120px', sm: '160px'},
  height: '36px',
  borderRadius: '4px',
  boxShadow: '0px 0px 6px 0px #00000040',
  background: 'linear-gradient(180deg, rgba(215, 182, 111, 0.81) 0%, #D7B66F 100%)',
  color: theme.palette.common.black,
}))

export const AffiliateButton = styled(Button)(({theme}) => ({
  height: '36px',
  borderRadius: '4px',
  // boxShadow: '0px 0px 6px 0px #00000040',
  backgroundColor: 'transparent',
  background: theme.palette.success.main,
  color: theme.palette.common.white,
  '&:hover': {
    background: theme.palette.success.main,
  },
}))
