import React from 'react'
import {ConsentManager} from '@segment/consent-manager'
import {Button} from '@mui/material'

import {ConsentManagerContainer} from './ConsentContainer'

import {CONSENT_MANAGER_KEY} from 'config'

const bannerContent = (
  <span>
    We use cookies (and other similar technologies) to collect data to improve your experience on
    our site.
  </span>
)
const bannerSubContent = 'You can manage your preferences here!'

const bannerActionsBlock = ({acceptAll, denyAll}) => (
  <div>
    <Button
      variant='contained'
      onClick={acceptAll}
      sx={{
        margin: '10px',
        height: {xs: '38px'},
        width: {xs: '150px'},
        backgroundColor: 'green',
        '&:hover': {
          backgroundColor: '#86C22E',
        },
      }}
    >
      Allow all
    </Button>
    <Button
      variant='contained'
      color='secondary'
      onClick={denyAll}
      sx={{
        margin: '10px',
        backgroundColor: 'white',
        height: {xs: '38px'},
        width: {xs: '150px'},
        '&:hover': {
          backgroundColor: '#86C22E',
          color: 'white',
        },
      }}
    >
      Allow only essential
    </Button>
  </div>
)
const preferencesDialogTitle = 'Website Data Collection Preferences'
const preferencesDialogContent = (
  <div>
    <p>
      Segment uses data collected by cookies and JavaScript libraries to improve your browsing
      experience, analyze site traffic, deliver personalized advertisements, and increase the
      overall performance of our site.
    </p>
    <p>
      The table below outlines how we use this data by category. To opt out of a category of data
      collection, select “No” and save your preferences.
    </p>
  </div>
)
const cancelDialogTitle = 'Are you sure you want to cancel?'
const cancelDialogContent = <div>Your preferences have not been saved.</div>
const euDefaultPreferences = {advertising: false, marketingAndAnalytics: false, functional: false}

const ConsentManagerExample = props => {
  return (
    <ConsentManagerContainer>
      <ConsentManager
        writeKey={CONSENT_MANAGER_KEY}
        bannerContent={bannerContent}
        bannerBackgroundColor={'#D7B66F'}
        bannerTextColor={'black'}
        bannerSubContent={bannerSubContent}
        bannerActionsBlock={bannerActionsBlock}
        bannerHideCloseButton={props.bannerHideCloseButton}
        preferencesDialogTitle={preferencesDialogTitle}
        preferencesDialogContent={preferencesDialogContent}
        cancelDialogTitle={cancelDialogTitle}
        cancelDialogContent={cancelDialogContent}
        closeBehavior={'deny'}
        initialPreferences={euDefaultPreferences}
      />
    </ConsentManagerContainer>
  )
}

export default ConsentManagerExample
