import {Box} from '@mui/material'
import React, {useState, useEffect, useRef} from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import SwiperCore, {Autoplay} from 'swiper'
import {useTranslation} from 'react-i18next'

import {loginUSUrl} from 'config'
import {useMediaQuery} from 'hooks'

import SignupSlide from './Sliders/Signup'
import CryptoSlide from './Sliders/Crypto'
import BwgSlide from './Sliders/BwgWallet'
import SliderController from './SliderController'

SwiperCore.use([Autoplay])

const SliderSection = () => {
  const {t} = useTranslation()
  const isMobile = useMediaQuery('sm', 'down')
  const [swiper, setSwiper] = useState()
  const [activeIndex, setActiveIndex] = useState(0)
  const slideIntervals = [12000, 12000, 12000]
  const [isVisible, setIsVisible] = useState(false)
  const swiperRef = useRef(null)

  useEffect(() => {
    const handleIntersection = entries => {
      const entry = entries[0]
      setIsVisible(entry.isIntersecting)
    }

    const options = {
      threshold: 0.5,
    }

    const observer = new IntersectionObserver(handleIntersection, options)
    observer.observe(swiperRef.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    if (swiper && isVisible && !isMobile) {
      swiper.autoplay.start()
      swiper.autoplay.delay = slideIntervals[activeIndex]
    } else if (swiper && (!isVisible || isMobile)) {
      swiper.autoplay.stop()
    }
    // eslint-disable-next-line
  }, [swiper, isVisible, activeIndex, isMobile])

  const handleActiveIndexChange = ({realIndex}) => {
    setActiveIndex(realIndex)
  }
  //TODO edit text when edit CryptoSlide
  const howToData = {
    '@context': 'http://schema.org',
    '@type': 'HowTo',
    name: t('slider.title'),
    description: t('slider.description'),
    step: [
      {
        '@type': 'HowToStep',
        name: t('slider.slide1.title'),
        text: t('slider.slide1.text'),
        video: {
          '@type': 'VideoObject',
          name: t('slider.slide1.title'),
          contentUrl: '/assets/sumsub.mp4',
          uploadDate: '2023-03-16',
          thumbnailUrl: '/assets/sumsubThumbnail.png',
        },
      },
      {
        '@type': 'HowToStep',
        name: t('slider.slide2.title'),
        text: t('slider.slide2.text', {link: loginUSUrl}),
        video: {
          '@type': 'VideoObject',
          name: t('slider.slide2.title'),
          contentUrl: '/assets/Slide2MetaMaskConnect.mp4',
          uploadDate: '2023-03-16',
          thumbnailUrl: '/assets/Slide2Thumbnail.png',
        },
      },
      {
        '@type': 'HowToStep',
        name: t('slider.slide3.title'),
        text: t('slider.slide3.text'),
        video: {
          '@type': 'VideoObject',
          name: t('slider.slide3.title'),
          contentUrl: '/assets/Slide3PurchaseTransactionFinal.mp4',
          uploadDate: '2023-03-16',
          thumbnailUrl: '/assets/Slide3Thumbnail.png',
        },
      },
    ],
  }

  return (
    <Box
      id='gold-platform'
      sx={{
        paddingTop: {xs: '60px', sm: '70px'},
      }}
    >
      <Box position='relative'>
        <script type='application/ld+json'>{JSON.stringify(howToData)}</script>
        <Swiper
          autoplay={{delay: slideIntervals[0]}}
          onSwiper={setSwiper}
          onActiveIndexChange={handleActiveIndexChange}
          ref={swiperRef}
        >
          <SwiperSlide>
            <SignupSlide />
          </SwiperSlide>
          <SwiperSlide>
            <CryptoSlide />
          </SwiperSlide>
          <SwiperSlide>
            <BwgSlide />
          </SwiperSlide>
        </Swiper>
        <SliderController swiper={swiper} current={activeIndex} />
      </Box>
    </Box>
  )
}

export default SliderSection
