import English from 'images/flags/en.svg'
import German from 'images/flags/de.svg'

export const LANGS = [
  {
    value: 'en',
    label: 'English',
    icon: English,
  },
  {
    value: 'de',
    label: 'German',
    icon: German,
  },
]
