import {Link as MuiLink} from '@mui/material'
import {styled} from '@mui/material/styles'

import {reversePath} from 'utils'
import {ReactComponent as BwgToken} from 'images/platform/bwg-logo.svg'

export const LogoWrapper = styled(props => (
  <MuiLink href={reversePath()} underline='none' color='inherit' {...props} />
))({
  display: 'flex',
  alignItems: 'center',
  columnGap: 15,
})

export const Logo = styled(BwgToken)(({theme}) => ({
  width: 55,
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: 50,
  },
}))

export const Link = styled(props => {
  return <MuiLink variant='body2' color='inherit' target='_self' fontWeight={600} {...props} />
})({})
