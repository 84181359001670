import {useTranslation} from 'react-i18next'
import GridUnstable from '@mui/material/Unstable_Grid2'
import {Box, Stack, Container, Grid, Typography, Tooltip} from '@mui/material'

import metamask from 'images/metamask.webp'
import wallet from 'images/bwg-connect-wallet.webp'
import {RegisterButton} from 'views/Affiliate/Affiliate.styles'
import ButtonBuyGold from 'components/ButtonBuyGoldModal/ButtonBuyGoldModal'

import {wallets} from './wallets.config'
import {TokenImage, ContentWrapper, MetamaskGrid, WalletIcon} from './ConnectWallet.styles'

const ConnectWallet = ({affiliateLink, customerLink, isChecked}) => {
  const {t} = useTranslation()

  const openRegisterLink = () => {
    window.open(affiliateLink, '_blank')
  }

  const openCustomerRegisterLink = () => {
    window.open(customerLink, '_blank')
  }

  return (
    <Container
      maxWidth='sm'
      disableGutters
      sx={{
        marginTop: 5,
        borderBottom: ({palette: {secondary}}) => `3px solid ${secondary.main}`,
        paddingBottom: {xs: 3, sm: 5},
      }}
    >
      <Grid container rowGap={3}>
        <Grid item xs={12}>
          <Grid
            container
            direction={{
              xs: 'column-reverse',
              sm: 'row',
            }}
            rowGap={1}
          >
            <Grid item xs={12} sm={7}>
              <Stack justifyContent='space-between' height='100%'>
                <TokenImage src={wallet} />
                <ContentWrapper>
                  <Typography variant='h2'>{t('connectWallet.title')}</Typography>
                  <Typography variant='body1'>{t('connectWallet.description')}</Typography>
                  {affiliateLink ? (
                    <>
                      {!isChecked ? (
                        <Tooltip
                          enterTouchDelay={0}
                          title={<span style={{fontSize: 16}}>{t('connectWallet.tooltip')}</span>}
                          arrow
                          placement='top-start'
                        >
                          <span>
                            <RegisterButton disableRipple disabled={!isChecked}>
                              {t('buttons.register.affiliate')}
                            </RegisterButton>
                          </span>
                        </Tooltip>
                      ) : (
                        <RegisterButton onClick={openRegisterLink}>
                          {t('buttons.register.affiliate')}
                        </RegisterButton>
                      )}
                      <RegisterButton onClick={openCustomerRegisterLink}>
                        {t('buttons.register.customer')}
                      </RegisterButton>
                    </>
                  ) : (
                    <ButtonBuyGold />
                  )}
                </ContentWrapper>
              </Stack>
            </Grid>
            <MetamaskGrid item xs={12} sm={5}>
              <Box component='img' src={metamask} height={350} width='auto' />
            </MetamaskGrid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <GridUnstable container rowGap={2} columns={10}>
            <GridUnstable xs={2}>
              <WalletIcon {...wallets[0]} />
            </GridUnstable>
            <GridUnstable xs={2} />
            <GridUnstable xs={2}>
              <WalletIcon {...wallets[1]} />
            </GridUnstable>
            <GridUnstable xs={2} />
            <GridUnstable xs={2}>
              <WalletIcon {...wallets[2]} />
            </GridUnstable>
            <GridUnstable xs={2} />
            <GridUnstable xs={2}>
              <WalletIcon {...wallets[3]} />
            </GridUnstable>
            <GridUnstable xs={2} />
            <GridUnstable xs={2}>
              <WalletIcon {...wallets[4]} />
            </GridUnstable>
            <GridUnstable xs={2} />
          </GridUnstable>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ConnectWallet
