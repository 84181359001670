import React from 'react'
import {useTranslation} from 'react-i18next'
import {Container, AppBar, Toolbar, Box, Typography} from '@mui/material'

import {isAffiliateLegalNotice, isAffiliateOrLandingHome, reversePathWithHash} from 'utils'
import Languages from 'components/Languages'
import HamburgerMenu from 'components/HamburgerMenu/HamburgerMenu'

import {getNavItems} from './Navbar.config'
import {Logo, LogoWrapper, Link} from './Navbar.styles'

const Navbar = () => {
  const {t} = useTranslation()

  const navLinks = React.useMemo(() => getNavItems(t), [t])

  return (
    <AppBar
      position='fixed'
      sx={{
        background: 'white',
        border: '1px solid #ccc',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
      }}
    >
      <Container>
        <Toolbar disableGutters>
          <LogoWrapper>
            <Logo />
            <Typography variant='body2' sx={{display: {xs: 'none', sm: 'block'}}}>
              Bretton Woods
              <br />
              <i>digital</i> Gold
            </Typography>
          </LogoWrapper>
          {!isAffiliateLegalNotice && (
            <Box display={{xs: 'none', md: 'flex'}} ml={8} columnGap={3}>
              {navLinks.map(({id, text, link, target}) => (
                <Link
                  key={id}
                  href={
                    isAffiliateOrLandingHome
                      ? link
                      : reversePathWithHash({path: '/', hasPath: link})
                  }
                  target={target}
                >
                  {text}
                </Link>
              ))}
            </Box>
          )}
          <Box
            flexGrow={1}
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
            columnGap={{
              xs: 1,
              sm: 2,
            }}
          >
            {/*inactive while only 1 language is used on the platform*/}
            <Box display='block'>
              <Languages />
            </Box>

            <Box display={{xs: 'block', md: 'none'}}>
              <HamburgerMenu />
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Navbar
