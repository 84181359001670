import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link, Typography} from '@mui/material'

import {useMediaQuery} from 'hooks'
import TrackCustomPreference from 'consentCheck'

const Tutorial = () => {
  const {t} = useTranslation()
  const isMobile = useMediaQuery('sm', 'down')

  return (
    <div style={{width: '100%', marginTop: '70px'}}>
      <iframe
        width='100%'
        height={isMobile ? '400' : '650'}
        allowFullScreen
        style={{borderRadius: 8, border: 'none'}}
        src={t('tutorialSection.videoUrl')}
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
      ></iframe>
      <Typography variant='subtitle1' sx={{marginTop: {xs: 2, md: 2}}}>
        {t('tutorialSection.info')}{' '}
        <Link
          target='_blank'
          href={t('tutorialSection.link')}
          color='success.main'
          onClick={() =>
            TrackCustomPreference({
              action: 'Link Clicked',
              properties: {
                url: t('tutorialSection.link'),
                text: t('tutorialSection.linkText'),
                meaning: 'Open brettonwoods digital website media',
              },
            })
          }
        >
          {t('tutorialSection.linkText')}
        </Link>
      </Typography>
    </div>
  )
}

export default Tutorial
