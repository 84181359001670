import {Box, Typography} from '@mui/material'
import {styled} from '@mui/material/styles'

export const Wrapper = styled(props => (
  <Box
    display='flex'
    flexDirection={{
      xs: 'row',
      sm: 'column',
    }}
    alignItems='center'
    justifyContent='space-between'
    left={{
      xs: '50%',
      sm: 50,
      md: 65,
    }}
    top={{
      xs: 80,
      sm: 262,
    }}
    height={{
      xs: 40,
      sm: 300,
    }}
    width={{
      xs: 250,
      sm: 30,
    }}
    {...props}
  />
))({
  position: 'absolute',
  zIndex: '998',
  fontWeight: 600,
  transform: 'translate(-50%, -50%)',
})

export const Number = styled(props => <Typography variant='h6' {...props} />)(
  ({theme, active}) => ({
    fontWeight: 600,
    cursor: 'pointer',
    userSelect: 'none',
    color: active ? theme.palette.secondary.main : theme.palette.text.reverse,
  })
)

export const Dot = styled('div')(({theme}) => ({
  height: 3,
  width: 3,
  borderRadius: '50%',
  background: theme.palette.text.reverse,
}))
