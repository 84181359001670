import {
  ItemLeft,
  ItemFull,
  ItemLeftContainer,
  VideoWrapper,
  Video,
  VideoMobile,
} from './sliders.styles'

const VideoSection = ({src, poster}) => (
  <>
    <ItemLeft sx={{display: {xs: 'none', sm: 'block'}}}>
      <ItemLeftContainer>
        <VideoWrapper>
          <Video src={src} />
        </VideoWrapper>
      </ItemLeftContainer>
    </ItemLeft>
    <ItemFull sx={{display: {xs: 'block', sm: 'none'}}}>
      <ItemLeftContainer>
        <VideoWrapper>
          <VideoMobile src={src} poster={poster} />
        </VideoWrapper>
      </ItemLeftContainer>
    </ItemFull>
  </>
)

export default VideoSection
