import {styled, Grid, Box} from '@mui/material'

export const HeroContainer = styled('div')(({theme}) => ({
  padding: '100px 15px 0 15px',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    padding: '80px 15px 0 15px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '70px 0 0 0',
  },
}))

export const ItemLeft = props => (
  <Grid
    item
    sm={7}
    sx={12}
    order={{xs: 2, sm: 1}}
    textAlign={{
      xs: 'center',
      sm: 'left',
    }}
    {...props}
  />
)

export const ItemRight = props => (
  <Grid  item  alignSelf='center' sm={5} xs={12} order={{xs: 1, sm: 2}} {...props} />
)

export const ItemLeftContainerGradient = styled('div')(({theme}) => ({
  backgroundImage: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%);`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}))

export const ItemLeftContainerSecond = styled('div')(({theme}) => ({
  padding: '20px 0 0 0',
  width: '450px',
  [theme.breakpoints.down('md')]: {
    width: '320px',
  },
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}))

export const ButtonContainer = styled('div')(({theme}) => ({
  padding: '50px 0 0 0',
  [theme.breakpoints.down('md')]: {
    padding: '20px 0 0 0',
  },
}))

export const ImageWrapper = styled('div')`
  height: auto;
  width: auto;
`
export const ImageBox = props => (
  <Box
    component='img'
    width='auto'
    height={{
      xs: '270px',
      sm: '210px',
      md: '300px',
      lg: '410px',
    }}
    {...props}
  />
)
