import {Typography, Grid, Box, styled} from '@mui/material'
import SustainabilityBackground from 'images/sustainable/SustainabilityBackground.webp'

export const SustainabilityContainer = styled('div')(({theme}) => ({
  padding: '150px 0 0 0',
  [theme.breakpoints.down('md')]: {
    padding: '70px 0 0 0',
  },
  width: '100%',
}))

export const SustainabilityPicContainer = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  height: '325px',
  background: `url(${SustainabilityBackground})`,
  borderRadius: '20px 20px 0px 0px;',
  backgroundSize: '100% 100%',
  padding: '0 15px 40px 40px',
  [theme.breakpoints.down('sm')]: {
    padding: '0 15px 40px 15px',
    height: '245px',
  },
}))

export const SustainabilityGradientContainer = styled('div')(({theme}) => ({
  background: 'linear-gradient(180deg, #125B50 0%, #072521 100%);',
  borderRadius: '0px 0px 20px 20px;',
  padding: '30px 25px 0 25px',
  [theme.breakpoints.down('sm')]: {
    padding: '20px 15px 0 15px',
  },
}))

export const ItemLeftImageContainer = styled('div')(({theme}) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  padding: '0 25px 0 0',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    padding: '0 0 0 0',
  },
}))

export const SustainabilityGrig = props => (
  <Grid
    container
    columns='20'
    spacing={2}
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'top',
    }}
    {...props}
  />
)

export const ItemSide = props => <Grid item lg={4} sm={5} xs={20} {...props} />

export const ItemCenter = props => <Grid item lg={12} sm={10} xs={20} {...props} />

export const ItemLeftContainer = styled('div')(({theme}) => ({
  padding: '0 15px 10px 0',
  rowGap: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  textAlign: 'right',
  borderRight: `4px solid ${theme.palette.secondary.main}`,
  [theme.breakpoints.down('sm')]: {
    borderRight: `0px`,
    padding: '0 10px 0 10px',
    textAlign: 'left',
  },
}))

export const TopTextDiv = styled('div')(({theme}) => ({
  display: 'flex',
  rowGap: '25px',
  flexDirection: 'column',
  justifyContent: 'space-around',
  padding: '0 0 50px 0',
  [theme.breakpoints.down('sm')]: {
    borderBottom: `4px solid ${theme.palette.secondary.main}`,
    padding: '0 0 15px 0',
  },
}))

export const BottomTextDiv = styled('div')(({theme}) => ({
  display: 'flex',
  rowGap: '25px',
  flexDirection: 'column',
  textAlign: 'left',
  justifyContent: 'space-around',
  padding: '0 0 50px 0',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    padding: '0 0 15px 0',
  },
}))

export const FlowerImageBox = props => (
  <Box
    component='img'
    width='auto'
    height={{
      xs: '50px',
      sm: '70px',
    }}
    {...props}
  />
)

export const BulBImageBox = props => (
  <Box component='img' width='auto' height='170px' display={{xs: 'none', sm: 'block'}} {...props} />
)

export const BulBImageBoxMobile = props => (
  <Box component='img' width='auto' height='170px' display={{xs: 'block', sm: 'none'}} {...props} />
)

export const ListH3 = styled(props => (
  <Typography variant='h3' color='text.reverse' fontSize={{xs: 22, sm: 16}} {...props} />
))({})
