import React from 'react'

const DocumentView = ({title, htmlFilePath}) => {
  return (
    <>
      <iframe
        title={title}
        src={htmlFilePath}
        style={{paddingTop: '50px', width: '100%', height: '100%'}}
      />
    </>
  )
}

export default DocumentView
