import {currentPath} from 'utils'

export const getFooterLinks = t => [
  {
    id: 1,
    text: t('footer.items.legalNotice'),
    link: '/legal-notice',
    _bankLink: currentPath('/legal-notice'),
  },
  {
    id: 2,
    text: t('footer.items.userAgreement'),
    link: '/user-agreement',
    _bankLink: '/documents/user_agreement.pdf',
  },
  {
    id: 3,
    text: t('footer.items.privacyStatement'),
    link: '/privacy-statement',
    _bankLink: '/documents/privacy_statement.pdf',
  },
  {
    id: 4,
    text: t('footer.items.termsAndConditions'),
    link: '/terms-and-conditions',
    _bankLink: '/documents/terms_and_conditions.pdf',
  },
  {
    id: 5,
    text: t('footer.items.whitepaper'),
    link: '/documents/whitepaper.pdf',
    _bankLink: '/documents/whitepaper.pdf',
  },
  {
    id: 6,
    text: t('footer.items.securityCertificate'),
    link: '/documents/security_certificate.pdf',
    _bankLink: '/documents/whitepaper.pdf',
  },
  // {
  //   id: 7,
  //   text: 'FAQ',
  //   link: '/documents/terms_and_conditions.pdf',
  // },
]
