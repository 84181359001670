import metamask from 'images/wallets/metamask.svg'
import ledger from 'images/wallets/ledger.svg'
import coinbaseWallet from 'images/wallets/coinbase.svg'
import trustWallet from 'images/wallets/trust-wallet.svg'
import trezor from 'images/wallets/trezor.svg'

export const wallets = [
  {
    src: metamask,
    link: 'https://metamask.io/',
    text: 'MetaMask Wallet',
  },
  {
    src: coinbaseWallet,
    link: 'https://www.coinbase.com/wallet',
    text: 'Coinbase Wallet',
  },
  {
    src: trustWallet,
    link: 'https://trustwallet.com/',
    text: 'Trust Wallet',
  },
  {
    src: trezor,
    link: 'https://affil.trezor.io/SHBf',
    text: 'Trezor Wallet',
  },
  {
    src: ledger,
    link: 'https://shop.ledger.com/?r=563caec193b6',
    text: 'Ledger Wallet',
  },
]
