import {alpha} from '@mui/material/styles'

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  1000: '#000000',
}

const PRIMARY = {
  light: '#46887c',
  main: '#125B50',
  dark: '#003128',
  contrastText: GREY[0],
}

const SECONDARY = {
  light: '#ffe89e',
  main: '#d7b66f',
  dark: '#a46842',
  contrastText: GREY[1000],
}

const INFO = {
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  contrastText: GREY[0],
}

const SUCCESS = {
  light: '#AAF27F',
  main: '#86C22E',
  dark: '#229A16',
  contrastText: GREY[800],
}

const WARNING = {
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  contrastText: GREY[800],
}

const ERROR = {
  light: '#FFA48D',
  main: '#E55050',
  dark: '#B72136',
  contrastText: GREY[0],
}

const palette = {
  common: {black: GREY[1000], white: GREY[0]},
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[1000],
    secondary: GREY[600],
    disabled: GREY[500],
    reverse: GREY[0],
  },
  background: {
    paper: GREY[0],
    default: GREY[0],
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
}

export default palette
