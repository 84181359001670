import React from 'react'
import {Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import GreenBulB from 'images/sustainable/GreenBulB.png'

import {
  ItemLeftContainer,
  SustainabilityPicContainer,
  SustainabilityContainer,
  SustainabilityGradientContainer,
  ItemSide,
  ItemCenter,
  SustainabilityGrig,
  TopTextDiv,
  BulBImageBox,
  BulBImageBoxMobile,
  ListH3,
} from './Sustainability.styles'

export default function Sustainability() {
  const {t} = useTranslation()

  return (
    <SustainabilityContainer>
      <SustainabilityPicContainer>
        <Typography variant='h1' color='text.reverse' sx={{whiteSpace: 'pre-line'}}>
          {t('sustainability.title')}
        </Typography>
      </SustainabilityPicContainer>
      <SustainabilityGradientContainer>
        <SustainabilityGrig>
          <ItemSide display={{xs: 'flex', sm: 'block'}} direction={{xs: 'row', sm: 'column'}}>
            <BulBImageBoxMobile src={GreenBulB} />
            <ItemLeftContainer>
              <ListH3>{t('sustainability.list1')}</ListH3>
              <ListH3>{t('sustainability.list2')}</ListH3>
              <ListH3>{t('sustainability.list3')}</ListH3>
            </ItemLeftContainer>
          </ItemSide>
          <ItemCenter>
            <TopTextDiv>
              <Typography
                variant='body1'
                color='text.reverse'
                sx={{display: {xs: 'none', md: 'block'}}}
              >
                {t('sustainability.description1')}
              </Typography>

              <Typography variant='body1' color='text.reverse'>
                {t('sustainability.description2')}
              </Typography>
            </TopTextDiv>
          </ItemCenter>
          <ItemSide>
            <BulBImageBox src={GreenBulB} />
          </ItemSide>
        </SustainabilityGrig>
      </SustainabilityGradientContainer>
    </SustainabilityContainer>
  )
}
