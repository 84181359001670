import React from 'react'
import {useTranslation} from 'react-i18next'
import {Typography, Grid} from '@mui/material'

import HeroPic from 'images/HeroPic.webp'
import ButtonBuyGold from 'components/ButtonBuyGoldModal/ButtonBuyGoldModal'

import {
  HeroContainer,
  ImageWrapper,
  ImageBox,
  ItemLeft,
  ItemLeftContainerGradient,
  ItemLeftContainerSecond,
  ButtonContainer,
  ItemRight,
} from './HeroSection.styles'

export default function HeroSection() {
  const {t} = useTranslation()

  return (
    <HeroContainer>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: {xs: 'column', sm: 'row'},
          alignItems: 'center',
        }}
      >
        <ItemLeft>
          <ItemLeftContainerGradient>
            <Typography variant='h1' fontSize={{lg: 70}} lineHeight={{lg: '80px'}}>
              {t('heroSection.title')}
            </Typography>
          </ItemLeftContainerGradient>
          <ItemLeftContainerSecond>
            <Typography variant='h3'>{t('heroSection.description')}</Typography>
          </ItemLeftContainerSecond>
          <ButtonContainer>
            <ButtonBuyGold />
          </ButtonContainer>
        </ItemLeft>
        <ItemRight>
          <ImageWrapper>
            <ImageBox src={HeroPic} />
          </ImageWrapper>
        </ItemRight>
      </Grid>
    </HeroContainer>
  )
}
