import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

const ScrollToHash = () => {
  const {pathname, hash} = useLocation()

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.substring(1)) // Remove '#' from hash
      if (element) {
        element.scrollIntoView({behavior: 'smooth'})
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return null
}

export default ScrollToHash
