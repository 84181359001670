const TrackCustomPreference = ({action, properties}) => {
  if (typeof window.analytics.user === 'function') {
    const customTrackingPreferences = window.analytics.user()?.traits()?.customTrackingPreferences
    if (
      customTrackingPreferences !== undefined &&
      customTrackingPreferences.marketingAndAnalytics
    ) {
      window.analytics.track(action, properties)
    }
  }
}

export default TrackCustomPreference
