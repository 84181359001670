import {useMemo} from 'react'
import {CssBaseline} from '@mui/material'
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles'

import palette from './Palette'
import typography from './Typography'
import DefaultStyles from './DefaultStyles'
import ComponentsOverrides from './overrides'

const ThemeProvider = ({children}) => {
  const themeOptions = useMemo(
    () => ({
      palette,
      typography,
    }),
    []
  )

  const theme = createTheme(themeOptions)
  theme.components = ComponentsOverrides(theme)

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <DefaultStyles />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
}

export default ThemeProvider
