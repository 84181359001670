import {Box, Link} from '@mui/material'
import {styled} from '@mui/material/styles'
import facebook from 'images/social/Facebook.svg'
import instagram from 'images/social/Instagram.svg'
import bscscan from 'images/social/BscScan.svg'

export const Container = styled(props => (
  <Box
    sx={{
      padding: {
        xs: '10px',
        sm: '20px 15px',
        md: '20px 30px',
        lg: '20px 40px',
        xl: '20px 60px',
      },
      display: 'flex',
      flexDirection: {
        xs: 'column-reverse',
        sm: 'row',
      },
      // justifyContent: 'space-between',
    }}
    rowGap={{xs: 1, sm: 0}}
    columnGap={2}
    {...props}
  />
))(({theme}) => ({
  background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, 
              ${theme.palette.primary.dark} 100%);`,
}))

export const Image = styled(props => (
  <Box component='img' sx={{width: {xs: 50, sm: 65}, height: 'auto'}} {...props} />
))({})

const SociaImage = styled(props => (
  <Box>
    <Link href={props.href} target='_blank'>
      <Box
        component='img'
        sx={{
          alignSelf: 'center',
          margin: '5px 5px 5px 0',
          padding: '10px',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '6px',
          width: {xs: 40, sm: 35, md: 40},
        }}
        src={props.src}
      />
    </Link>
  </Box>
))({})

export const LinkWrapper = styled(props => (
  <Box
    paddingLeft={{sm: '0', md: '30px'}}
    alignSelf={{
      xs: 'flex-start',
      sm: 'center',
    }}
    sx={{display: {xs: 'none', sm: 'flex'}}}
    // display='flex'
    flexDirection={{
      xs: 'column',
      md: 'row',
    }}
    gap={{
      xs: '5px',
      md: 4,
    }}
    ml={{
      xs: '58px',
      sm: 0,
    }}
    {...props}
  />
))({})

export const BoxSocialMedia = styled(props => (
  <Box display={'flex'} alignItems={'center'} {...props}>
    <SociaImage src={facebook} href='https://www.facebook.com/profile.php?id=61551851876560' />
    <SociaImage src={instagram} href='https://www.instagram.com/bretton_woods_digital_ag' />
    <SociaImage
      src={bscscan}
      href='https://bscscan.com/token/0xda47Ba3A9F1DCb61C057Efe1e5d6b6654241c3Dd'
    />
  </Box>
))({})
