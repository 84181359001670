import {useMemo, useState} from 'react'
import {alpha} from '@mui/material/styles'
import {useTranslation} from 'react-i18next'
import {Box, MenuItem, Stack, Popover, Button} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import {LANGS} from './Languages.config'

const Languages = () => {
  const {i18n} = useTranslation()
  const [open, setOpen] = useState(null)

  const handleOpen = event => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const selectedLanguage = useMemo(
    () => LANGS.find(lang => lang.value === i18n.language) || LANGS[0],
    [i18n.language]
  )

  return (
    <>
      <Button
        onClick={handleOpen}
        color='inherit'
        sx={{
          textTransform: 'uppercase',
          bgcolor: ({palette: {primary, action}}) =>
            open ? alpha(primary.main, action.focusOpacity) : 'unset',
        }}
        startIcon={
          <Box
            component='img'
            alt={selectedLanguage.label}
            src={selectedLanguage.icon}
            sx={{width: 16, height: 16}}
          />
        }
        endIcon={
          <KeyboardArrowDownIcon
            sx={{
              transform: open ? 'rotate(180deg)' : 'unset',
              transition: ({transitions}) =>
                transitions.create(['transform'], {
                  duration: transitions.duration.standard,
                }),
            }}
          />
        }
      >
        {selectedLanguage.value}
      </Button>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map(option => (
            <MenuItem
              key={option.value}
              selected={option.value === i18n.language}
              onClick={() => {
                i18n.changeLanguage(option.value)
                handleClose()
              }}
            >
              <Box component='img' alt={option.label} src={option.icon} sx={{width: 28, mr: 2}} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  )
}

export default Languages
