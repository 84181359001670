import {Container} from '@mui/material'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import {Trans, useTranslation} from 'react-i18next'

import BWGFooterImg from 'images/platform/bwg-footer-logo.svg'

import {Wrapper, Section, ImageContainer, Img} from './LegalNotice.styles'

const LegalNotice = () => {
  const {t} = useTranslation()

  return (
    <Container sx={{marginTop: {xs: '50px', sm: 0}}}>
      <Wrapper>
        <Typography variant='h4' fontSize={24}>
          {t('footer.legalNotice.title')}
        </Typography>

        <Section>
          <Grid container spacing={1}>
            <Grid item md={4} sm={5} xs={12}>
              <ImageContainer>
                <Img src={BWGFooterImg} />
                <Typography variant='body2' fontSize={17} sx={{whiteSpace: 'pre-line'}}>
                  <Trans i18nKey='footer.legalNotice.euCompanyAddress'>
                    <i>digital</i>
                  </Trans>
                </Typography>
              </ImageContainer>
            </Grid>

            <Grid item md={8} sm={7} xs={12} marginTop={{xs: 2, sm: 0}}>
              <Box display={'flex'} columnGap={2} sx={{flexDirection: {xs: 'column', sm: 'row'}}}>
                <Typography variant='subtitle1' width={120}>
                  {t('footer.legalNotice.phoneTitle')}
                </Typography>
                <Link
                  fontSize={17}
                  underline='1'
                  sx={{color: theme => theme.palette.common.black}}
                  href='tel:+41 41 711 18 09'
                >
                  +41 41 711 18 09
                </Link>
              </Box>

              <Box
                display={'flex'}
                marginTop={1}
                columnGap={2}
                sx={{flexDirection: {xs: 'column', sm: 'row'}}}
              >
                <Typography variant='subtitle1' width={120}>
                  {t('footer.legalNotice.emailTitle')}
                </Typography>
                <Link
                  fontSize={17}
                  underline='1'
                  href='mailto:info@brettonwoods.ch'
                  sx={{color: theme => theme.palette.common.black}}
                >
                  info@brettonwoods.ch
                </Link>
              </Box>

              <Box
                display={'flex'}
                marginTop={1}
                columnGap={2}
                sx={{flexDirection: {xs: 'column', sm: 'row'}}}
              >
                <Typography variant='subtitle1' width={120}>
                  {t('footer.legalNotice.vatIdTitle')}
                </Typography>
                <Typography style={{fontSize: 16}}>CHE-345.459.158</Typography>
              </Box>
            </Grid>
          </Grid>
        </Section>

        {/* CEO SECTION */}
        <Section>
          <Grid container spacing={1}>
            <Grid item md={4} sm={5} xs={12}>
              <Box display={'flex'} columnGap={2} sx={{flexDirection: {xs: 'row', sm: 'column'}}}>
                <Typography variant='subtitle1'>CEO:</Typography>
                <Typography style={{fontSize: 16}}>Norbert M. Schmidt</Typography>
              </Box>
            </Grid>
            <Grid item md={8} sm={7} xs={12}>
              <Box display={'flex'} columnGap={2} flexDirection={'column'}>
                <Typography variant='subtitle1'>
                  {t('footer.legalNotice.designationTitle')}
                </Typography>
                <Typography style={{fontSize: 16}}>Norbert M. Schmidt</Typography>
              </Box>
            </Grid>
          </Grid>
        </Section>

        {/* DATA PROTECTION OFFICER SECTION */}
        <Section>
          <Grid container spacing={1}>
            <Grid item md={4} sm={5} xs={12}>
              <Box display={'flex'} flexDirection={'column'}>
                <Typography variant='subtitle1'>
                  {' '}
                  {t('footer.legalNotice.protectionOfficerTitle')}
                </Typography>
                <Typography style={{fontSize: 16}}>Sebastian Block</Typography>
              </Box>
            </Grid>

            <Grid item md={8} sm={7} xs={12}>
              <Box display={'flex'} flexDirection={'column'}>
                <Typography variant='subtitle1'>{t('footer.legalNotice.emailTitle')}</Typography>
                <Link
                  fontSize={17}
                  underline='1'
                  href='mailto:datenschutz@brettonwoods.ch'
                  sx={{color: theme => theme.palette.common.black}}
                >
                  datenschutz@brettonwoods.ch
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Section>

        {/* US PLATFORM DATA */}
        <Section>
          <Grid container spacing={1}>
            <Grid item md={4} sm={5} xs={12}>
              <ImageContainer>
                <Img src={BWGFooterImg} />
                <Typography variant='body2' fontSize={17} sx={{whiteSpace: 'pre-line'}}>
                  <Trans i18nKey='footer.legalNotice.usCompanyAddress'>
                    <i>digital</i>
                  </Trans>
                </Typography>
              </ImageContainer>
            </Grid>

            <Grid item md={8} sm={7} xs={12} marginTop={{xs: 2, sm: 0}}>
              <Box
                display={'flex'}
                marginTop={1}
                columnGap={2}
                sx={{flexDirection: {xs: 'column', sm: 'row'}}}
              >
                <Typography variant='subtitle1' width={120}>
                  EIN:
                </Typography>
                <Typography style={{fontSize: 16}}>93-3413856</Typography>
              </Box>
            </Grid>
          </Grid>
        </Section>

        {/* CEO SECTION */}
        <Section>
          <Grid container spacing={1} paddingBottom={{xs: 5}}>
            <Grid item md={4} sm={5} xs={12}>
              <Box display={'flex'} columnGap={2} sx={{flexDirection: {xs: 'row', sm: 'column'}}}>
                <Typography variant='subtitle1'>CEO:</Typography>
                <Typography style={{fontSize: 16}}>Norbert M. Schmidt</Typography>
              </Box>
            </Grid>
          </Grid>
        </Section>
      </Wrapper>
    </Container>
  )
}

export default LegalNotice
