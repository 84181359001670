import {styled} from '@mui/material/styles'

export const Wrapper = styled('div')(({theme}) => ({
  paddingTop: 100,
  paddingLeft: 15,
  [theme.breakpoints.down('sm')]: {
    paddingTop: 50,
  },
}))

export const Section = styled('div')({
  marginTop: 30,
})

// TODO delete if not used
export const SectionWithTopLine = styled('div')(({theme}) => ({
  marginTop: 30,
  borderTop: `2px solid ${theme.palette.secondary.main}`,
  padding: '30px 0 0 0',
}))

export const ImageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
})

export const Img = styled('img')({
  height: 80,
  width: 80,
})
