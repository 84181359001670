export default function Link(theme) {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          textUnderlineOffset: 4,
        },
      },
    },
  }
}
