export const getNavItems = t =>
  Object.freeze([
    {
      id: 1,
      text: t('navigation.menuItems.bwg'),
      link: '#bwg-section',
      target: '_self',
    },
    {
      id: 2,
      text: t('navigation.menuItems.business'),
      link: '#business-model',
      target: '_self',
    },
    {
      id: 3,
      text: t('navigation.menuItems.goldPlatform'),
      link: '#gold-platform',
      target: '_self',
    },
    {
      id: 4,
      text: t('navigation.menuItems.whitePaper'),
      link: '/documents/whitepaper.pdf',
      target: '_blank',
    },
    {
      id: 5,
      text: t('navigation.menuItems.securityCertificate'),
      link: '/documents/security_certificate.pdf',
      target: '_blank',
    },
  ])
