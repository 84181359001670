import {Box, Typography, styled, ListItem} from '@mui/material'

export const Container = styled(props => (
  <Box
    marginY={{xs: 2}}
    paddingY={{xs: 3, sm: 4, md: 5}}
    paddingX={{xs: 0, sm: 2, md: 3}}
    borderRadius='15px'
    sx={{
      background: ({palette: {primary}}) => `linear-gradient(180deg, ${primary.main} 0%, 
        ${primary.dark} 100%);`,
    }}
    {...props}
  />
))({})

export const Title = styled(props => (
  <Typography
    variant='body1'
    textAlign='center'
    fontSize={({typography: {body1}}) => ({xs: 10, sm: 15, ...body1})}
    fontWeight={600}
    lineHeight={{xs: '14px', sm: '22px', md: '28px'}}
    {...props}
  />
))({})

export const BottomContainer = styled('div')(({theme}) => ({
  padding: '15px 10px 0 0',
  [theme.breakpoints.down('sm')]: {
    padding: '15px 10px 0 10px',
  },
}))

export const BulletedListItem = styled(props => (
  <ListItem sx={{display: 'list-item', paddingY: 1, paddingX: 0}} {...props} />
))({})
