export default function AppBar(theme) {
  return {
    MuiAppBar: {
      defaultProps: {
        position: 'relative',
        color: 'transparent',
      },
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  }
}
