import {Stack, Typography} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import {styled} from '@mui/material/styles'

export const Title = styled(props => (
  <Typography
    variant='h2'
    color='text.reverse'
    mb={{
      xs: 5,
      sm: 3,
    }}
    textAlign={{
      xs: 'center',
      sm: 'start',
    }}
    paddingLeft={{sm: '50px', md: '80px'}}
    fontSize={{xs: 25, md: 28}}
    {...props}
  />
))({})

export const SliderContainer = styled(props => (
  <Box
    padding={{
      xs: 3,
      sm: 6,
    }}
    {...props}
  />
))(({theme}) => ({
  width: '100%',
  borderRadius: '15px',
  background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, 
               ${theme.palette.primary.dark} 100%);`,
}))

export const ItemLeft = props => <Grid item {...props} />

export const ItemRight = props => <Grid item xs {...props} />

export const ItemFull = props => <Grid item xs={12} {...props} />

export const ItemRightContainer = styled(props => (
  <Stack justifyContent='center' alignItems='flex-start' rowGap={2} {...props} />
))(({theme}) => ({
  height: '100%',
  padding: '0 0 5px 10px',
  borderLeft: `4px solid ${theme.palette.secondary.main}`,
}))

export const ItemLeftContainer = styled(props => (
  <Stack
    alignItems={{xs: 'center', sm: 'flex-end'}}
    paddingLeft={{sm: '50px', md: '80px'}}
    {...props}
  />
))(({theme}) => ({}))

export const VideoWrapper = styled(props => (
  <Box
    width={{
      xs: '200px',
      sm: '170px',
      md: '200px',
    }}
    {...props}
  />
))({
  height: 'auto',
  borderRadius: 15,
  overflow: 'hidden',
})

export const Video = styled(props => (
  <Box
    component='video'
    width='100%'
    height='auto'
    autoPlay
    loop
    muted
    {...props}
    ref={ref => {
      if (ref) {
        ref.currentTime = 0
      }
    }}
  />
))({
  transform: 'scale(1.05)',
})

export const VideoMobile = styled(props => (
  <Box component='video' width='100%' height='auto' controls loop muted {...props} />
))({
  transform: 'scale(1.03)',
})
