import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Typography, Grid, Box} from '@mui/material'

import BWGCoinMockup from 'images/BWGCoinMockup.webp'

import {
  BWGContainer,
  ImageWrapper,
  ImageBox,
  ItemLeft,
  ItemRight,
  ItemLeftContainer,
  BigLeftContainer,
} from './BWGSection.styles.js'

export default function BWGSection() {
  const {i18n, t} = useTranslation()

  return (
    <Box
      id='bwg-section'
      sx={{
        paddingTop: {xs: '60px', sm: '70px'},
      }}
    >
      <BWGContainer>
        <Grid
          container
          columns={20}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ItemLeft>
            <BigLeftContainer>
              <ItemLeftContainer>
                <Typography variant='h3' color='text.reverse'>
                  <Trans i18nKey='bwgSection.title' key={i18n.language}>
                    <i>digital</i>
                  </Trans>
                </Typography>

                <Typography variant='body1' color='text.reverse' sx={{whiteSpace: 'pre-line'}}>
                  {t('bwgSection.description')}
                </Typography>
              </ItemLeftContainer>
            </BigLeftContainer>
          </ItemLeft>
          <ItemRight>
            <ImageWrapper>
              <ImageBox src={BWGCoinMockup}></ImageBox>
            </ImageWrapper>
          </ItemRight>
        </Grid>
      </BWGContainer>
    </Box>
  )
}
