import React from 'react'
import {useTranslation} from 'react-i18next'
import {Typography, Grid} from '@mui/material'

import BusinessModel1 from 'images/business_model/BusinessModel1.png'
import BusinessModel2 from 'images/business_model/BusinessModel2.png'
import BusinessModel3 from 'images/business_model/BusinessModel3.png'

import {
  FirstContainer,
  Item,
  ImageWrapper,
  ImageBox,
  ItemFirst,
  ItemSecond,
  ItemThird,
  ImageBoxPhone,
  ImageWrapperPhone,
  BusinessModelContainer,
} from './BusinessModel.styles'

export default function BusinessModel() {
  const {t} = useTranslation()

  return (
    <BusinessModelContainer id='business-model'>
      <FirstContainer>
        <Typography variant='h2' sx={{textAlign: {xs: 'left', md: 'center'}}}>
          {t('businessModel.title')}
        </Typography>
      </FirstContainer>
      <Grid container>
        <Item>
          <ImageWrapperPhone>
            <ImageBoxPhone src={BusinessModel1} />
          </ImageWrapperPhone>
          <ItemFirst>
            <ImageWrapper>
              <ImageBox src={BusinessModel1} />
            </ImageWrapper>
            <Typography variant='h3' textAlign={{xs: 'left', sm: 'center'}}>
              {t('businessModel.subTitle1')}
            </Typography>
            <Typography variant='body1' textAlign={{xs: 'left', sm: 'center'}}>
              {t('businessModel.description1')}
            </Typography>
          </ItemFirst>
        </Item>
        <Item>
          <ImageWrapperPhone>
            <ImageBoxPhone src={BusinessModel2} />
          </ImageWrapperPhone>

          <ItemSecond>
            <ImageWrapper>
              <ImageBox src={BusinessModel2} />
            </ImageWrapper>
            <Typography variant='h3' textAlign={{xs: 'left', sm: 'center'}}>
              {t('businessModel.subTitle2')}
            </Typography>
            <Typography variant='body1' textAlign={{xs: 'left', sm: 'center'}}>
              {t('businessModel.description2')}
            </Typography>
          </ItemSecond>
        </Item>
        <Item>
          <ImageWrapperPhone>
            <ImageBoxPhone src={BusinessModel3} />
          </ImageWrapperPhone>
          <ItemThird>
            <ImageWrapper>
              <ImageBox src={BusinessModel3} />
            </ImageWrapper>
            <Typography variant='h3' textAlign={{xs: 'left', sm: 'center'}}>
              {t('businessModel.subTitle3')}
            </Typography>
            <Typography variant='body1' textAlign={{xs: 'left', sm: 'center'}}>
              {t('businessModel.description3')}
            </Typography>
          </ItemThird>
        </Item>
      </Grid>
    </BusinessModelContainer>
  )
}
